import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';

declare var $: any;
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  isLoader = true;
  constructor(
  	private cService: CustomService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
    /*$('#gmap').on('load', function () {
          alert('test');
            $('.loader').hide();
        });*/
  }

  myLoadEvent(){ this.isLoader = false; }

}
