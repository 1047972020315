<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Products</h2>
					<p>Home | Data Infrastructure </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Data Warehouse</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Data Staging Platform</a>
						</li>
						
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px;" >Data Warehouse</p>

<p style="padding-top:0px; text-align:justify">We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have a team of in-house experts and a network of capable professionals that we constantly tap into at our disposal for seamless integration and execution.</p>
<p style="padding-top:0px; text-align:justify">
We have developed Unified Cloud Data Warehouses for several clients integrating all their data sources into one single instance seamlessly. We develop a solution that fits the client’s current stack, needs and vision.</p>


							
							
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px;" >Data Staging Platform</p>

<p style="padding-top:0px; text-align:justify">We provide a data staging platform that builds a structured data pipeline from various data sources for reporting and analytics. It integrates all data sources into one single unified database and updates the data regularly through scheduled ETL processes. This is database-as-a-service (DAAS), in the cloud or on-premise.</p>



						</div>
						
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>