import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';    

@Injectable({
  providedIn: 'root'
})
export class CustomService {
	constructor() { }

	invokeOnloadScript = new EventEmitter();    
	loadVar: Subscription; 

	onLoadScript() {    
		this.invokeOnloadScript.emit();    
	} 
}
