<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Proucts</h2>
					<p>Home | Products </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Analytics SDK</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Data Infrastructure</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false">Reporting Portal</a>
						</li>
						
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							<h2>Analytics SDK</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Web SDK</p>

<p style="padding-top:0px;">Measuring user behavior by user, session and timestamp is paramount to growth. Our web SDKs help you track all user activities in your website(s) to help with product and business decisions. Here are our SDKs for the web.</p>
<ul style="color:#f1f1f1; list-style-type:none;">
							<li><i class="fa fa-check" style="color:#e58202;"></i> Javascript SDK</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> PHP SDK</li>
</ul>

<p  style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Mobile SDK</p>

<p>We have SDKs for mobile that help track user activity in mobile apps at the microscopic level. Here are our SDKs for mobile:</p>
<ul style="color:#f1f1f1; list-style-type:none;">
							<li><i class="fa fa-check" style="color:#e58202;"></i> iOS SDK</li>
							<li><i class="fa fa-check" style="color:#e58202;"></i>Android SDK</li>
							</ul>

							
							
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							<h2>Data Infrastructure</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Data Warehouse</p>

<p style="padding-top:0px;">We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have a team of in-house experts and a network of capable professionals that we constantly tap into at our disposal for seamless integration and execution.</p>

<p>We have developed Unified Cloud Data Warehouses for several clients integrating all their data sources into one single instance seamlessly. We develop a solution that fits the client’s current stack, needs and vision.</p>


<p  style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Data Staging Platform</p>

<p>We provide a data staging platform that builds a structured data pipeline from various data sources for reporting and analytics. It integrates all data sources into one single unified database and updates the data regularly through scheduled ETL processes. This is database-as-a-service (DAAS), in the cloud or on-premise.</p>

						</div>
						<div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
							<h2>Reporting Portal</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Standard Portal</p>

<p style="padding-top:0px;">We have our own analytics portal product that clients can use as an off-the-shelf product. We can white label the portal to suit the client’s needs and requirements. The portal is also very modular making it easy to include or exclude various modules seamlessly.</p>

<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Custom Portal</p>

<p style="padding-top:0px;">Apart from the standard reporting portal, we can develop a custom portal for you for your needs. We are specialists in data and analytics and have developed such portals in the past with much success.</p>

<p>
Contact us today to learn more about our custom analytics product development and services.</p>

						</div>
						
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>