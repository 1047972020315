<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Customer Success Stories</h2>
					<p>Resources | Customer Success Stories </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Prominent&nbsp;Social&nbsp;Media&nbsp;Influencer</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">E-commerce Company</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false">Prominent Telecom Client</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-4" role="tab" aria-controls="tab-4" aria-selected="false">Online Education Platform</a>
						</li>
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							
							<h2>Prominent&nbsp;Social&nbsp;Media&nbsp;Influencer</h2>
							<p>Project: Full Analytics Suite</p>
							<div><ul style="color:#f1f1f1; list-style-type:none;">
							<li><i class="fa fa-check" style="color:#e58202;"></i> Unified Data Warehouse</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Reporting and Visualization</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Analytics and Data Science</li>

							</ul></div><p></p>
							<p>Data Analytics Platform (design below) helped the client get a full understanding of and grow their customers across all their channels</p>
							<img src="assets/img/blog/10.png">
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							<h2>E-commerce Company</h2>
							<p>Project: Unifying all their data sources to create a data warehouse to suit their analytics needs.</p>
							<p>Unified Data Warehouse in AWS (design below) helped them gain insights on their customers from various sources to be able to segment and target various customers.</p>
							<img src="assets/img/blog/11.png">
						</div>
						<div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
							<h2>Prominent Telecom Client</h2>
							<p>Project: Full Scale Data Analytics: Roadmap (below) developed with the vision for analytics through integration of all data sources and business logic for full scale business operations.</p>
							<img src="assets/img/blog/12.png">
						</div>
						<div class="tab-pane fade" id="tab-4" role="tabpanel" aria-labelledby="tab-4">
							<h2>Online Education Platform</h2>
							<div><p>Project: Full Analytics Suite</p>
							<ul style="color:#f1f1f1; list-style-type:none;">
							<li><i class="fa fa-check" style="color:#e58202;"></i> Unified Data Warehouse</li>
							<li><i class="fa fa-check" style="color:#e58202;"></i> Reporting and Visualization</li>
							<li><i class="fa fa-check" style="color:#e58202;"></i> Analytics and Data Science</li>
							</ul>
							</div><p></p>
							<p>Recommended architecture (below) developed to unify all data sources as well as develop reporting and analytics capabilities for insights into their customer base.</p>
							<img src="assets/img/blog/13.png">
						</div>
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>