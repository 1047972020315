<!-- Page top section  -->
<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
   <div class="container">
      <div class="row">
         <div class="col-lg-7">
            <h2>Products</h2>
            <p>Analytics SDK | Web SDK </p>
         </div>
      </div>
   </div>
</section>
<!-- Page top section end  -->
<!-- About section -->
<section class="blog-section spad">
   <div class="container">
      <div class="row">
         <div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
            <div class="sb-widget">
               <div class="info-box" >
                  <h3>Contact Us for Help</h3>
                  <p style="color:#f1f1f1;">You can contact us to know more about our products and services. </p>
                  <div class="footer-info-box">
                     <div class="fib-icon">
                        <img src="assets/img/icons/phone.png" alt="" class="">
                     </div>
                     <div class="fib-text" >
                        <p  style="color:#f1f1f1;">626-240-4600<br>
                           hello@datonique.com
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-lg-8 order-1 order-lg-2">
            <div class="blog-post">
               <div class="blog-thumb set-bg" data-setbg="assets/img/blog/2.jpg">
               </div>
               <h2>Web SDK</h2>
               <p>Measuring user behavior by user, session and timestamp is paramount to growth. Our web SDKs help you track all user activities in your website(s) to help with product and business decisions. Here are our SDKs for the web.</p>
               <div>
	               <ul style="padding-left:25px;">
	                  <li>Javascript SDK</li>
	                  <li>PHP SDK</li>
	               </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>