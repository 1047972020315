<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Contact Us</h2>
					<p>Home | Contact Us </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->
<div class="map-section">
		<div class="container">
			
		</div>
		<div class="map">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.9990947345696!2d-118.13045778478254!3d34.14636528058044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c35b6a30b92f%3A0xdb4f59a7d2baf1c9!2s1055%20E%20Colorado%20Blvd%20%23500%2C%20Pasadena%2C%20CA%2091106%2C%20USA!5e0!3m2!1sen!2sin!4v1601311942071!5m2!1sen!2sin" style="border:0" allowfullscreen  id="gmap" (load)="myLoadEvent()"></iframe>
		</div>
		<div class="loader" *ngIf="isLoader"></div>
	</div>

	<!-- About section -->
	<section class="contact-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<div class="contact-text">
						<h2>Get in Touch</h2>
						<p>We would love to hear from you. Please fill out the form and we will get back to you as soon as possible.

Here are some things we help with
</p>
						<div class="header-info-box">
							<div class="hib-icon">
								<img src="assets/img/icons/phone.png" alt="" class="">
							</div>
							<div class="hib-text">
								<h6>626-240-4600</h6>
								<p>hello@datonique.com</p>
							</div>
						</div>
						<div class="header-info-box">
							<div class="hib-icon">
								<img src="assets/img/icons/map-marker.png" alt="" class="">
							</div>
							<div class="hib-text">
								<h6>1055 E. Colorado Blvd, </h6>
								<p>Suite 500, Pasadena CA 91106</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-8">
				<div class="row">
				<div class="col-lg-6"><div class="form-group">
    <label for="one">Name</label>
    <input type="text" class="form-control" id="one">
    
  </div></div>
				<div class="col-lg-6">
				<div class="form-group">
    <label for="two">Email</label>
    <input type="email" class="form-control" id="two">
    
  </div>
				</div>
				</div>
				<div class="row">
				<div class="col-lg-6"><div class="form-group">
    <label for="three">Phone Number</label>
    <input type="email" class="form-control" id="three">
    
  </div></div>
				<div class="col-lg-6">
				<div class="form-group">
    <label for="four">Company</label>
    <input type="email" class="form-control" id="four">
    
  </div>
				</div>
				</div>
				<div class="row">
				<div class="col-lg-6"><div class="form-group">
    <label for="five">Title</label>
    <input type="email" class="form-control" id="five">
    
  </div></div>
  <div class="col-lg-6"><div class="form-group">
    <label for="six">Topic or Query</label>
    <select class="form-control" >
  <option>select</option>
  <option>Analytics SDK</option>
  <option>Data Infrastructure</option>
  <option>Reporting Portal</option>
  <option>Data Analytics</option>
  <option>Digital Transformation</option>
  <option>Growth Marketing</option>
</select>
    
  </div></div>
				</div>
				
				
				<div class="row">
				<div class="col-lg-12"><div class="form-group">
    <label for="one">Description</label>
    <textarea class="form-control" placeholder="Description"></textarea>
    
  </div></div>
				</div>
				<div class="row">
				<div class="col-lg-12"><div class="form-group">
    <label for="one">How did you find us</label>
    <select class="form-control" >
  <option>select</option>
  <option>Internet Search</option>
  <option>Recommendation</option>
  <option>Google</option>
  <option>Other Search engine</option>
  <option>Facebook</option>
  <option>Family & Friends</option>
</select>
    <button class="site-btn" type="submit" style="margin-top:30px;">send message</button>
  </div></div>
				</div>
				
					
				</div>
			</div>
		</div>
	</section>