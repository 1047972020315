<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Services</h2>
					<p>Data Analytics | Growth Marketing / Growth Hacking  </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="blog-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
					
					
					
					<div class="sb-widget">
						<div class="info-box" >
							<h3>Contact Us for Help</h3>
							<p style="color:#f1f1f1;">You can contact us to know more about our products and services. </p>
							<div class="footer-info-box">
								<div class="fib-icon">
									<img src="assets/img/icons/phone.png" alt="" class="">
								</div>
								<div class="fib-text" >
									<p  style="color:#f1f1f1;">626-240-4600<br>
hello@datonique.com</p>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
				<div class="col-lg-8 order-1 order-lg-2">
					<div class="blog-post">
												
						<h2>Growth Marketing / Growth Hacking</h2>
						<p>Growth marketing/hacking applies to startups or companies that plan to grow customers, users or digital footprint substantially, ideally after achieving product-market fit, through </p>
						<ul style="list-style-type:none;">
						<li><i class="fa fa-check" style="color:#e58202;"></i> Trial and Error methods<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Hacking creative and novel techniques<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Experimentation<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Viral schemes<li>
						</ul>
						
						<div class="row" style="padding-top:20px;">
						<div class="col-xl-12">
						<!-- Accordions -->
						<div id="accordion" class="accordion-area">
							<div class="panel">
								<div class="panel-header active" id="headingOne">
									<button class="panel-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">Capabilities</button>
								</div>
								<div id="collapse1" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
									<div class="panel-body">
										<p>We have a high level of expertise in full stack technology development, data and analytics. </p>
										<ul style="list-style-type:none;">
						<li><i class="fa fa-check" style="color:#e58202;"></i> Technology and Data Product Management (Roadmap, Technology Design, KPI Definition, Systems Overhaul and Development including Web and Mobile Apps)
<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Data Engineering (Cloud Computing, Big Data, ETL, Automation and Scheduling)<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Data Reporting (Tableau, Qlikview, QuickSight, Looker etc)<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Data Science (Attribution, Predictive Modeling, Algorithms, AI, Machine Learning etc.)<li>
						</ul>
									</div>
								</div>
							</div>
							<div class="panel">
								<div class="panel-header" id="headingTwo">
									<button class="panel-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">Timeline and Pricing</button>
								</div>
								<div id="collapse2" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
									<div class="panel-body">
										<p style="text-align:justify">The biggest advantage of hiring us is that we are a high power technology, data and analytics team that delivers quickly and efficiently yet at a fraction of the cost of an in-house team. This way, you can just focus on your product and business. Our team of seasoned professionals have done this over and over again for several clients. Therefore we follow best practices in all areas of development - engineering, product and analytics.</p>
									</div>
								</div>
							</div>
							<div class="panel">
								<div class="panel-header" id="headingThree">
									<button class="panel-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">Operating Process</button>
								</div>
								<div id="collapse3" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
									<div class="panel-body">
										<p style="text-align:justify">Typically, we start with a discovery or audit phase. The objective of this discovery phase is to clarify the existing system and process landscape within the business and identify the needs of the business going forward from a system and process perspective. The output from this phase is an ‘Audit Assessment Report’ that contains the observations, recommendations and requirements of the overall project which will feed directly into the proposal for the subsequent implementation phase. Methods employed may include interviews, focus groups, informational meetings, questionnaires/requests for information, system walk-throughs and assessments, and any other methods deemed necessary. The implementation phase is usually developed in quarterly intervals with clear milestones for every interval. </p>
									</div>
								</div>
							</div>
						</div>
					</div>
						</div>
						
					</div>
					
					
					
				</div>
			</div>
		</div>
	</section>