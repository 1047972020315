<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Products</h2>
					<p>Home | Reporting Portal </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Standard Portal</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Custom Portal</a>
						</li>
						
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px;" >Standard Portal</p>

<p style="padding-top:0px; text-align:justify">We have our own analytics portal product that clients can use as an off-the-shelf product. We can white label the portal to suit the client’s needs and requirements. The portal is also very modular making it easy to include or exclude various modules seamlessly.</p>



							
							
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px;" >Custom Portal</p>

<p style="padding-top:0px; text-align:justify">Apart from the standard reporting portal, we can develop a custom portal for you for your needs. We are specialists in data and analytics and have developed such portals in the past with much success.</p>

<p style="padding-top:0px; text-align:justify">Contact us today to learn more about our custom analytics product development and services.</p>



						</div>
						
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>