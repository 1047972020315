<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Products</h2>
					<p>Home | Analytics SDK </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Web SDK</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Mobile SDK</a>
						</li>
						
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px;" >Web SDK</p>

<p style="padding-top:0px;">Measuring user behavior by user, session and timestamp is paramount to growth. Our web SDKs help you track all user activities in your website(s) to help with product and business decisions. Here are our SDKs for the web.</p>
<ul style="color:#f1f1f1; list-style-type:none;">
						<li><i class="fa fa-check" style="color:#e58202;"></i> Javascript SDK<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> PHP SDK<li>						
						</ul>


							
							
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px;" >Mobile SDK</p>

<p style="padding-top:0px;">We have SDKs for mobile that help track user activity in mobile apps at the microscopic level. Here are our SDKs for mobile.</p>

<ul style="color:#f1f1f1; list-style-type:none;">
						<li><i class="fa fa-check" style="color:#e58202;"></i> iOS SDK<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Android SDK<li>						
						</ul>



						</div>
						
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>