<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Services</h2>
					<p>Home | Services </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Data Analytics</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Digital Transformation</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false">Growth Marketing</a>
						</li>
						
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							<h2>Data Analytics</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Data Infrastructure</p>

<p style="padding-top:0px;">The first pillar is establishing data infrastructure. It involves setting up the data instruments and production systems to siphon data in readable format into a robust data warehouse through automated ETL processes.</p>


<p  style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Reporting and Visualization</p>

<p>The second pillar is to set up analytics and reporting tools that would enable data literacy by defining key success metrics (also called Key Performance Indicators or KPIs) for the company. This will help tune the product/business on some quick insights based on reporting. This pillar includes reporting tool development, establishing KPIs and data visualization.</p>
<p  style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Insights and Data Science</p>
<p>The third pillar involves driving deeper insights, data science initiatives (AI/Machine Learning/Predictive Modeling) that ask and answer some difficult questions that require the use of sophisticated analyses such as predictive analytics, regression modeling, principal component analysis (PCA), Machine Learning etc. These are more involved and usually require (1) and (2) in steady state to be undertaken (much later in the life cycle).</p>
							
							
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							<h2>Digital Transformation</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Digital Transformation</p>

<p style="padding-top:0px;">We are experts in building new systems, overhauling or replacing archaic systems and technologies with scalable and futuristic systems through an organized and seasoned approach that has proven to be successful over several years of development.</p>





						</div>
						
						<div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
							<h2>Growth Marketing</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Growth Marketing / Growth Hacking</p>

<p style="padding-top:0px;">Growth marketing/hacking applies to startups or companies that plan to grow customers, users or digital footprint substantially, ideally after achieving product-market fit, through</p>

<ul style="color:#f1f1f1; list-style-type:none;">
<li><i class="fa fa-check" style="color:#e58202;"></i> Trial and Error methods</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Hacking creative and novel techniques</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Experimentation</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Viral schemes</li>
</ul>

 <p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Capabilities</p>

<p style="padding-top:0px;">We have a high level of expertise in full stack technology development, data and analytics.</p>

<ul style="color:#f1f1f1; list-style-type:none;">
<li><i class="fa fa-check" style="color:#e58202;"></i> Technology and Data Product Management (Roadmap, Technology Design, KPI Definition, Systems Overhaul and Development including Web and Mobile Apps)</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Data Engineering (Cloud Computing, Big Data, ETL, Automation and Scheduling)</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Data Reporting (Tableau, Qlikview, QuickSight, Looker etc)</li>
<li><i class="fa fa-check" style="color:#e58202;"></i> Data Science (Attribution, Predictive Modeling, Algorithms, AI, Machine Learning etc.)</li>
</ul>

 <p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Timeline and Pricing</p>

<p style="padding-top:0px; text-align:justify;">The biggest advantage of hiring us is that we are a high power technology, data and analytics team that delivers quickly and efficiently yet at a fraction of the cost of an in-house team. This way, you can just focus on your product and business. Our team of seasoned professionals have done this over and over again for several clients. Therefore we follow best practices in all areas of development - engineering, product and analytics.</p>
<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Operating Process</p>

<p style="padding-top:0px; text-align:justify;">Typically, we start with a discovery or audit phase. The objective of this discovery phase is to clarify the existing system and process landscape within the business and identify the needs of the business going forward from a system and process perspective. The output from this phase is an ‘Audit Assessment Report’ that contains the observations, recommendations and requirements of the overall project which will feed directly into the proposal for the subsequent implementation phase. Methods employed may include interviews, focus groups, informational meetings, questionnaires/requests for information, system walk-throughs and assessments, and any other methods deemed necessary. The implementation phase is usually developed in quarterly intervals with clear milestones for every interval.</p>
 
 
 




						</div>
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>