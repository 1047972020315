import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/site/home/home.component';
import { WebsdkComponent } from './components/site/websdk/websdk.component';
import { MobilesdkComponent } from './components/site/mobilesdk/mobilesdk.component';
import { WarehouseComponent } from './components/site/warehouse/warehouse.component';
import { StagingplatformComponent } from './components/site/stagingplatform/stagingplatform.component';
import { StandardportalComponent } from './components/site/standardportal/standardportal.component';
import { CustomportalComponent } from './components/site/customportal/customportal.component';
import { DatainfraComponent } from './components/site/datainfra/datainfra.component';
import { ReportingComponent } from './components/site/reporting/reporting.component';
import { DatascienceComponent } from './components/site/datascience/datascience.component';
import { MarketingComponent } from './components/site/marketing/marketing.component';
import { CustomerComponent } from './components/site/customer/customer.component';
import { ContactusComponent } from './components/site/contactus/contactus.component';
import { MissionComponent } from './components/site/mission/mission.component';
import { TeamComponent } from './components/site/team/team.component';
import { TestmonialsComponent } from './components/site/testmonials/testmonials.component';
import { CareersComponent } from './components/site/careers/careers.component';
import { AnalyticsdkComponent } from './components/site/analyticsdk/analyticsdk.component';
import { DatainfrastructureComponent } from './components/site/datainfrastructure/datainfrastructure.component';
import { ReportingportalComponent } from './components/site/reportingportal/reportingportal.component';
import { ServicesComponent } from './components/site/services/services.component';
import { DigitaltransformComponent } from './components/site/digitaltransform/digitaltransform.component';
import { ResourcesComponent } from './components/site/resources/resources.component';
import { AboutComponent } from './components/site/about/about.component';
import { DatanalyticsComponent } from './components/site/datanalytics/datanalytics.component';
import { ProductsComponent } from './components/site/products/products.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', component: HomeComponent, data : {title:'Home'} },
  { path: 'WebSDK', component: WebsdkComponent, data : {title:'Web SDK'} },
  { path: 'MobileSDK', component: MobilesdkComponent, data : {title:'Mobile SDK'} },
  { path: 'DataWarehouse', component: WarehouseComponent, data : {title:'Data Warehouse'} },
  { path: 'StagingPlatform', component: StagingplatformComponent, data : {title:'Data Staging Platform'} },
  { path: 'StandardPortal', component: StandardportalComponent, data : {title:'Standard Portal'} },
  { path: 'CustomPortal', component: CustomportalComponent, data : {title:'Custom Portal'} },
  { path: 'DataInfrastructure', component: DatainfraComponent, data : {title:'Data Infrastructure'} },
  { path: 'Report', component: ReportingComponent, data : {title:'Reporting and Visualization'} },
  { path: 'DataScience', component: DatascienceComponent, data : {title:'Data Science'} },
  { path: 'Marketing', component: MarketingComponent, data : {title:'Growth Marketing'} },
  { path: 'Customer', component: CustomerComponent, data : {title:'Customer Success Stories'} },
  { path: 'ContactUs', component: ContactusComponent, data : {title:'Contact Us'} },
  { path: 'Mission', component: MissionComponent, data : {title:'Mission'} },
  { path: 'Team', component: TeamComponent, data : {title:'Team'} },
  { path: 'Testimonials', component: TestmonialsComponent, data : {title:'Client Testimonials'} },
  { path: 'Careers', component: CareersComponent, data : {title:'Careers'} },
  { path: 'AnalyticsSDK', component: AnalyticsdkComponent, data : {title:'Analytics SDK'} },
  { path: 'Infrastructure', component: DatainfrastructureComponent, data : {title:'Data Infrastructure'} },
  { path: 'ReportingPortal', component: ReportingportalComponent, data : {title:'Reporting Portal'} },
  { path: 'Services', component: ServicesComponent, data : {title:'Services'} },
  { path: 'DigitalTransformation', component: DigitaltransformComponent, data : {title:'Digital Transformation'} },
  { path: 'Resources', component: ResourcesComponent, data : {title:'Resources'} },
  { path: 'About', component: AboutComponent, data : {title:'About'} },
  { path: 'DataAnalytics', component: DatanalyticsComponent, data : {title:'Data Analytics'} },
  { path: 'Products', component: ProductsComponent, data : {title:'Products'} },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', useHash: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

