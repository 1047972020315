import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';

@Component({
  selector: 'app-testmonials',
  templateUrl: './testmonials.component.html',
  styleUrls: ['./testmonials.component.css']
})
export class TestmonialsComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

}
