import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';   

@Component({
  selector: 'app-datainfrastructure',
  templateUrl: './datainfrastructure.component.html',
  styleUrls: ['./datainfrastructure.component.css']
})
export class DatainfrastructureComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

}
