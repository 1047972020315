<!-- Hero section  -->
<section class="hero-section">
   <div class="hero-slider owl-carousel">
      <div class="hero-item set-bg" data-setbg="assets/img/hero-slider/1.jpg">
         <div class="container">
            <div class="row">
               <div class="col-xl-8">
                  
                  
               </div>
            </div>
         </div>
      </div>
      <div class="hero-item set-bg" data-setbg="assets/img/hero-slider/2.jpg">
         <div class="container">
            <div class="row">
               <div class="col-xl-8">
                  <h2><span>Digital</span><span>Transformation</span></h2>
                  
               </div>
            </div>
         </div>
      </div>
      <div class="hero-item set-bg" data-setbg="assets/img/hero-slider/3.jpg">
         <div class="container">
            <div class="row">
               <div class="col-xl-8">
                  <h2><span>Growth</span><span>Marketing</span></h2>
                  
               </div>
            </div>
         </div>
      </div>
      <div class="hero-item set-bg" data-setbg="assets/img/hero-slider/4.jpg">
         <div class="container">
            <div class="row">
               <div class="col-xl-8">
                  <h2><span>Request</span><span>Demo</span></h2>
                  
                  <a href="javascript://" routerLinkActive="active" routerLink="/ContactUs" class="site-btn sb-dark">Demo</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Hero section end  -->
<!-- Services section  -->
<section class="services-section">
   <div class="services-warp">
      <div class="container">
         <div class="row">
         <div class="col-lg-12" style="text-align:center;">
        <h4 class="head-title" style="padding-bottom:20px;"> Our Products &nbsp;</h4>
         </div>
         </div>
         
         <div class="row">
            
            <div class="col-lg-4 col-md-6">
               <div class="service-item">
                  <div class="si-head">
                     <div class="si-icon">
                        <i class="fa fa-user new"></i>
                     </div>
                     <h5>Analytics SDK</h5>
                  </div>
                  <p class="moretext" style="text-align:justify;">Measure your platform user behavior at the microscopic level by installing our web and mobile SDKs in your product. You can instrument events for every user activity i.e., intra/inter session behavior, engagement patterns, interaction with various product sections, monetization, retention/churn etc.</p>
                  <a href="javascript://" routerLinkActive="active" routerLink="/AnalyticsSDK" class="btn btn-primary btn-sm pull-right;" style="font-size:12px; border-radius:15px; margin-top:10px; right: 38px;
 position: absolute;">Read More</a>
               </div>
            </div>
            <div class="col-lg-4 col-md-6">
               <div class="service-item">
                  <div class="si-head">
                     <div class="si-icon">
                        <i class="fa fa-bullhorn new"></i>
                     </div>
                     <h5>Data Infrastructure</h5>
                  </div>
                  <p class="moretext" style="text-align:justify;">We work with every technology and stack under the sun. Be it modern technology such as AWS, Microsoft, Google Cloud Stacks or traditional technologies such as MySQL, Vertica, Oracle we have been there, done that. Data Engineering is one of our core specialties.</p>
                  <a href="javascript://" routerLinkActive="active" routerLink="/Infrastructure" class="btn btn-primary btn-sm pull-right;" style="font-size:12px; border-radius:15px; margin-top:10px; right: 38px;
 position: absolute;">Read More</a>
               </div>
            </div>
            <div class="col-lg-4 col-md-6">
               <div class="service-item">
                  <div class="si-head">
                     <div class="si-icon">
                        <i class="fa fa-cog new"></i>
                     </div>
                     <h5>Reporting Portal</h5>
                  </div>
                  <p class="moretext" style="text-align:justify;">Use our standard off-the-shelf portal for your reporting needs. We can white label it so it can look and feel like your own product. For a custom portal, contact us for scoping and development.</p>
                  <a href="javascript://" routerLinkActive="active" routerLink="/ReportingPortal" class="btn btn-primary btn-sm pull-right;" style="font-size:12px; border-radius:15px; margin-top:10px; right: 38px;
 position: absolute;">Read More</a>
               </div>
            </div>
                 
         </div>
         
         
         <div class="row">
         <div class="col-lg-12" style="text-align:center;">
        <h4 class="head-title" style="padding-bottom:20px;"> Services &nbsp;</h4>
         </div>
      <div class="col-lg-4 col-md-6">
               <div class="service-item">
                  <div class="si-head">
                     <div class="si-icon">
                        <i class="fa fa-user-circle-o new"></i>
                     </div>
                     <h5>Data Analytics</h5>
                  </div>
                  <p class="moretext" style="text-align:justify;">Our core expertise is in building a robust data analytics platform for our clients that takes them into the future. We follow the 3-pillar approach to implement the best analytics solutions for you. These pillars are Data Infrastructure, Reporting & Visualization and Data Science (IRIS pillars).
</p><a  href="javascript://" routerLinkActive="active" routerLink="/DataAnalytics" class="btn btn-primary btn-sm pull-right;" style="font-size:12px; border-radius:15px; margin-top:10px; right: 38px;
 position: absolute;">Read More</a>
               </div>
            </div>
            <div class="col-lg-4 col-md-6">
               <div class="service-item">
                  <div class="si-head">
                     <div class="si-icon">
                        <i class="fa fa-eye new"></i>
                     </div>
                     <h5>Digital Transformation</h5>
                  </div>
                  <p class="moretext" style="text-align:justify;">We are experts in building new systems, overhauling or replacing archaic systems with scalable and futuristic technologies through an organized and seasoned approach that has proven to be successful over several years of development.</p><a href="javascript://" routerLinkActive="active" routerLink="/DigitalTransformation" class="btn btn-primary btn-sm pull-right;" style="font-size:12px; border-radius:15px; margin-top:10px; right: 38px;
 position: absolute;">Read More</a>
               </div>
            </div>
            <div class="col-lg-4 col-md-6">
               <div class="service-item">
                  <div class="si-head">
                     <div class="si-icon">
                        <i class="fa fa-tachometer new"></i>
                     </div>
                     <h5>Growth Marketing</h5>
                  </div>
                  <p class="moretext" style="text-align:justify;">Growth Marketing applies to companies that are looking to grow their customer base substantially over a relatively small period of time. We have helped clients with modern digital growth marketing techniques or strategies and have been particularly successful due to our analytical approach.</p>
                  <a  href="javascript://" routerLinkActive="active" routerLink="/Marketing" class="btn btn-primary btn-sm pull-right;" style="font-size:12px; border-radius:15px; margin-top:10px; right: 38px;
 position: absolute;">Read More</a>
               </div>
            </div>
         </div>
            
      </div>
   </div>
</section>
<!-- Services section end  -->

<!-- Features section   -->

<!-- Features section end  -->


<!-- Clients section  -->
<section class="clients-section">
   <div class="container">
      <div class="client-text">
         <h2>Our Clients</h2>
         
      </div>
      <div id="client-carousel" class="client-slider owl-carousel">
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/1.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/2.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/3.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/4.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/5.jpg" alt="">
            </a>
         </div>
             <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/6.jpg" alt="">
            </a>
         </div>
             <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/7.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/8.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/9.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/10.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/11.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/12.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/13.jpg" alt="">
            </a>
         </div>
         <div class="single-brand">
            <a href="javascript://">
               <img src="assets/img/clients/14.jpg" alt="">
            </a>
         </div>
      </div>
   </div>
</section>
<!-- Clients section end  -->


<!-- Testimonial section -->
<section class="testimonial-section">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-6 p-0">
            <div class="testimonial-bg set-bg" data-setbg="assets/img/testimonial-bg.jpg"></div>
         </div>
         <div class="col-lg-6 p-0">
            <div class="testimonial-box">
               <div class="testi-box-warp">
                  <h2>Testimonials</h2>
                  <div class="testimonial-slider owl-carousel">
                     <div class="testimonial">
                        <p>Murali and his team at Datonique continually impress me with their ability to take complex, messy data and transform it into invaluable insights. On top of that, they’re an absolute pleasure to work with.</p>
                        <img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
                        <div class="testi-info">
                           <h5>- Gavin Chan</h5>
                           <span>Chief Operating Officer at Hippo Education</span>
                        </div>
                     </div>
                     <div class="testimonial">
                        <p>Murali is one of my favorite people. He was VP, Head of Analytics at Victorious where he was responsible for putting together a world class data infrastructure and analytics framework. We were able to learn a lot about our product and users in part due to his awesome product acumen and strategic mindset.</p>
                        <img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
                        <div class="testi-info">
                           <h5>- Sam Rogoway </h5>
                           <span>Chief Product & Content Officer at Headspace, formerly CEO of Victorious </span>
                        </div>
                     </div>
                     <div class="testimonial">
                        <p>The comment has been made around TeleSign by people working with Murali for the first time that it’s really refreshing to have someone who is fluent in system architecture and data reporting who has such strong business acumen. The combination is really difficult to find.</p>
                        <img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
                        <div class="testi-info">
                           <h5>- Tim Weerasiri</h5>
                           <span>Chief Financial Officer at TeleSign Corporation</span>
                        </div>
                     </div>
                     <div class="testimonial">
                        <p>As Founder and CEO of Datonique, Murali is making the entire world data literate. I cannot wait to see the fantastic analytics products he is developing right now. Datonique is the quintessential data analytics and digital transformation powerhouse.</p>
                        <img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
                        <div class="testi-info">
                           <h5>- Sam Rogoway</h5>
                           <span>Chief Product & Content Officer at Headspace, formerly CEO of Victorious</span>
                        </div>
                     </div>
                     <div class="testimonial">
                        <p>After coming on board to help me, Murali has been pulled into a number of other workflows because just having him there to provide insights from multiple perspectives is invaluable.</p>
                        <img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
                        <div class="testi-info">
                           <h5>- Tim Weerasiri</h5>
                           <span>Chief Financial Officer at TeleSign Corporation</span>
                        </div>
                     </div>
                     <div class="testimonial">
                        <p>Datonique is an invaluable partner in helping Full Stack Finance financially and analytically support early stage companies. Their unique blend of business and technology insights of data infrastructures help efficiently set up the analytical framework and instrumentation necessary for early stage companies to be successful.

</p>
                        <img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
                        <div class="testi-info">
                           <h5>- Rick Gombos</h5>
                           <span>Partner and CFO at Full Stack Finance</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Testimonial section end  -->