<!-- Header section  -->
<header class="header-section clearfix">
   <div class="header-top">
      <div class="container-fluid">
         <div class="row">
            <div class="col-md-6">
               <p ><i class="fa fa-globe"></i> www.datonique.com</p>
            </div>
            <div class="col-md-6 text-md-right">
               <p><i class="fa fa-phone"></i> 626-240-4600 | <i class="fa fa-envelope"></i> hello@datonique.com |  <i class="fa fa-user"></i>&nbsp;<a href="https://analytics.datonique.com" class="logtext">Login</a></p>
            </div>
         </div>
      </div>
   </div>
   <div class="site-navbar">
      <!-- Logo -->
      <a href="javascript://" routerLinkActive="active" routerLink="/index" class="site-logo">
      <img src="assets/img/logo.png" alt="">
      </a>
      <div class="header-right">
         <div class="header-info-box">
            <div class="hib-text" style="padding-top:0px; margin-right:20px;">
               <a routerLinkActive="active" routerLink="/ContactUs" class="btn btn-orange btn-sm" style=" border-radius:20px;">Free Evaluation / Demo</a>
            </div>
         </div>
         <div class="header-info-box">
            <div class="hib-text">
               <form>
                  <div class="input-group" style="padding-bottom:5px;" >
                     <input type="text" class="form-control form-control-sm" placeholder="Search" style="margin-right:-3px;">
                     <div class="input-group-btn">
                        <button class="btn btn-primary btn-sm" type="submit">
                        <i class="fa fa-search"></i>
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
      <!-- Menu -->
      <nav style="display:inline-block;">
         <div class="stellarnav">
            <ul style="z-index:9999;">
               <li><a href="javascript://" routerLinkActive="active" routerLink="/index">Home</a></li>
               <li>
                  <a href="javascript://" routerLinkActive="active" routerLink="/Products">Products</a>
                  <ul>
                     <li>
                        <a href="javascript://" routerLinkActive="active" routerLink="/AnalyticsSDK">Analytics SDK</a>
                        <ul>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/WebSDK">Web SDK</a></li>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/MobileSDK">Mobile SDK</a></li>
                        </ul>
                     </li>
                     <li>
                        <a href="javascript://" routerLinkActive="active" routerLink="/Infrastructure">Data Infrastructure</a>
                        <ul>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/DataWarehouse">Data Warehouse</a></li>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/StagingPlatform">Data Staging Platform</a></li>
                        </ul>
                     </li>
                     <li>
                        <a href="javascript://" routerLinkActive="active" routerLink="/ReportingPortal">Reporting Portal</a>
                        <ul>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/StandardPortal">Standard Portal</a></li>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/CustomPortal">Custom Portal</a></li>
                        </ul>
                     </li>
                  </ul>
               </li>
               <li>
                  <a href="javascript://" routerLinkActive="active" routerLink="/Services">Services</a>
                  <ul>
                     <li>
                        <a href="javascript://" routerLinkActive="active" routerLink="/DataAnalytics">Data Analytics</a>
                        <ul>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/DataInfrastructure">Data Infrastructure</a></li>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/Report">Reporting and Visualization</a></li>
                           <li><a href="javascript://" routerLinkActive="active" routerLink="/DataScience">Data Science</a></li>
                        </ul>
                     </li>
                     <li>
                        <a href="javascript://" routerLinkActive="active" routerLink="/DigitalTransformation">Digital Transformation</a>
                     </li>
                     <li><a routerLinkActive="active" routerLink="/Marketing">Growth Marketing</a></li>
                  </ul>
               </li>
               <li>
                  <a href="javascript://" routerLinkActive="active" routerLink="/Resources">Resources</a>
                  <ul>
                     <li><a href="javascript://" routerLinkActive="active" routerLink="/Customer">Customer Success Stories</a></li>
                     <li><a href="javascript://" routerLinkActive="active" routerLink="/ContactUs">Contact Us</a></li>
                  </ul>
               </li>
               <li>
                  <a href="javascript://" routerLinkActive="active" routerLink="/About">About</a>
                  <ul>
                     <li><a href="javascript://" routerLinkActive="active" routerLink="/Mission">Mission</a></li>
                     <li><a href="javascript://" routerLinkActive="active" routerLink="/Team">Founder</a></li>
                     <li><a href="javascript://" routerLinkActive="active" routerLink="/Testimonials">Client Testimonials</a></li>
                     <li><a href="javascript://" routerLinkActive="active" routerLink="/Careers">Careers</a></li>
                  </ul>
               </li>
            </ul>
         </div>
      </nav>
      <!-- .stellarnav -->
      <!-- Menu -->
   </div>
</header>
<!-- Header section end  -->